/**
 * 제작 : 한승현
 * 날짜 : 2022/10/07
 * 내용 : 사용자 입력값을 받을 인풋, 서브밋 버튼
 */

import React from 'react';

import {
  EmailInputLabelStyled,
  EmailInputContainerStyled,
  EmailInputTitleStyled,
  EmailInputSubmitBtnStyled,
} from '../../stylesheets/sendForm/emailInput';

function EmailInput({ name, placeholder, register }) {
  return (
    <>
      <EmailInputLabelStyled htmlFor={name}>
        <EmailInputTitleStyled
          id={name}
        >{`${name}을 입력해주세요!`}</EmailInputTitleStyled>
        <EmailInputContainerStyled
          required
          placeholder={'(ex)' + placeholder}
          {...register('email', { required: true })}
        />
      </EmailInputLabelStyled>
      <EmailInputSubmitBtnStyled>이메일 발송</EmailInputSubmitBtnStyled>
    </>
  );
}

export default EmailInput;
