import React from 'react';
import { ToastContainer } from 'react-toastify';
import SendForm from './components/sendForm/SendForm';

function App() {
  return (
    <>
      <ToastContainer
        autoClose={1500}
        position="top-center"
        style={{ zIndex: 9999 }}
      />
      <SendForm />
    </>
  );
}

export default App;
