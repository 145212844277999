import styled from "styled-components";

const EmailInputLabelStyled = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 20px;
`

const EmailInputContainerStyled = styled.input`
  width: 250px;
  padding: 10px 8px;    
  outline: none;
  border: 1px solid #ccc;
`;

const EmailInputTitleStyled = styled.p`
  margin-bottom: 10px;
`;

const EmailInputSubmitBtnStyled = styled.button`
  padding: 10px;
`;

export {
    EmailInputTitleStyled,
    EmailInputLabelStyled,
    EmailInputContainerStyled,
    EmailInputSubmitBtnStyled,
}
